<template>
  <v-layout wrap justify-center id="cf">
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12>
      <v-layout wrap>
        <v-flex xs12>
          <v-card
            class="mx-auto elevation-1 pa-6"
            align="center"
            outlined
          >
            <v-layout wrap>
              <v-flex
                xs12
                sm7
                md6
                lg6
                pb-2
                pb-md-0
                class="text-left nsbold"
                style="font-weight: 700; font-size: 20px"
                >Shop List
              </v-flex>
              <v-flex
                xs12
                sm3
                md4
                lg4
                pb-2
                pb-md-0
                align-self-center
                class="text-left nsregular"
              >
                <v-text-field
                  v-model="keyword"
                  dense
                  class="rounded-xl"
                  label="Search"
                  outlined
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
              <v-flex xs2 sm2 md2 pb-0 pb-sm-2 pb-md-0 px-1 align-self-center>
                <!-- <v-card rounded outlined  style="cursor: pointer" color="transparent" @click="adddialogue = true"> -->
                <v-btn
                  width="100px"
                  class="rounded-lg"
                  dark
                  color="#766BC0"
                  @click="adddialogue = true"
                >
                  <v-icon x-small>mdi-plus</v-icon>
                  <span
                    class="text-left nsregular"
                    style="font-weight: 400; font-size: 12px"
                    >Add</span
                  ></v-btn
                >
                <!-- </v-card> -->
              </v-flex>
            </v-layout>
            <v-layout py-3>
              <v-flex
                xs1
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>No.</span>
              </v-flex>
              <v-flex
                xs2
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Name</span>
              </v-flex>
              <v-flex
                xs4
                class="text-left nsbold"
                style="font-weight: 700; font-size: 18px"
              >
                <span>Location</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsbold"
                style="font-weight: 600; font-size: 18px"
              >
                <span>Contact</span>
              </v-flex>
            </v-layout>
            <v-layout
              v-for="(item, i) in student"
              :key="i"
              py-2
              :style="i % 2 == 0 ? 'background-color:#FAFAFA' : ''"
            >
              <v-flex
                xs1
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <!-- <span>{{ i + 1 }}</span> -->
                <span v-if="page == 1">
                  {{ i + 1 }}
                </span>
                <span v-else>
                  {{ i + 1 + 20 * (page - 1) }}
                </span>
              </v-flex>
              <v-flex
                xs2
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <router-link
                  :to="'/adminShopHistory?id=' + item._id + '&name=' + item.shopName"
                  style="text-decoration: none"
                >
                  <span>{{ item.shopName }}</span>
                </router-link>
              </v-flex>
              <v-flex
                xs4
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.location }}</span>
              </v-flex>
              <v-flex
                xs3
                class="text-left nsregular"
                style="font-weight: 400; font-size: 18px"
              >
                <span>{{ item.phoneNumber }}</span>
              </v-flex>
              <v-flex xs1 v-if="item">
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(curItem = item), (editdialogue = true)"
                    >mdi-circle-edit-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-flex xs1>
                <span
                  ><v-icon
                    style="cursor: pointer"
                    @click="(dialoge = true), (curId = item._id)"
                    >mdi-delete-outline</v-icon
                  ></span
                >
              </v-flex>
              <v-divider></v-divider>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>

      <v-dialog width="400px" v-model="dialoge">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Confirm Delete </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pa-4 black--text"
            >Are you sure you want to delete this record?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="dialoge = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="deleteCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="400px" v-model="editdialogue">
        <v-card width="400px">
          <v-toolbar dark color="#766BC0" dense flat class="body-2">
            <v-toolbar-title> Edit Item </v-toolbar-title>
          </v-toolbar>

          <v-layout wrap v-if="curItem" pt-4>
            <v-flex xs12 px-4 py-2>
              <v-text-field
                v-model="curItem.shopName"
                outlined
                dense
                label="Shopname"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 px-4 pb-2>
              <v-text-field
                v-model="curItem.location"
                outlined
                dense
                label="Location"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 px-4>
              <v-text-field
                v-model="curItem.phoneNumber"
                outlined
                dense
                type="number"
                label="Contact"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-card-actions class="pt-3">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="editdialogue = false"
              >Cancel</v-btn
            >
            <v-btn
              color="#766BC0"
              class="body-2 font-weight-bold"
              outlined
              @click="editCategory()"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-form ref="form" v-model="valid">
        <v-dialog width="400px" v-model="adddialogue">
          <v-card width="400px">
            <v-toolbar dark color="#766BC0" dense flat class="body-2">
              <v-toolbar-title> Add Item </v-toolbar-title>
            </v-toolbar>

            <v-layout wrap pt-4>
              <v-flex xs12 px-4 py-2>
                <v-text-field
                  v-model="shopName"
                  :rules="[rules.required]"
                  outlined
                  dense
                  label="Shopname"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 pb-2>
                <v-text-field
                  v-model="location"
                  :rules="[rules.required]"
                  outlined
                  dense
                  label="Location"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4>
                <v-text-field
                  v-model="phoneNumber"
                  type="number"
                  outlined
                  dense
                  label="Contact"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="adddialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#766BC0"
                class="body-2 font-weight-bold"
                outlined
                @click="addCategory()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
      <v-layout wrap>
        <v-flex xs12 pt-4>
          <v-pagination
            small
            color="#766BC0"
             total-visible="7"
            v-model="page"
            :length="Pagelength"
          >
          </v-pagination>
        </v-flex>
      </v-layout>
      <v-snackbar
        :timeout="3000"
        :value="true"
        right
        color="red"
        v-model="showsnackbar"
      >
        <span style="font-size: 20px"> {{ msg }}</span>
      </v-snackbar>
    </v-flex>
  </v-layout>
</template>    
<script>
import axios from "axios";
export default {
  data() {
    return {
      student: [],
      dialoge: false,
      editdialogue: false,
      adddialogue: false,
      name: null,
      curId: null,
      curItem: null,
      showsnackbar: false,
      msg: null,
      page: 1,
      Pagelength: 0,
      appLoading: false,
      ServerError: false,
      typeList: null,
      type: null,
      keyword: null,
      valid: true,
      phoneNumber: null,
      shopName: null,
      location: null,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        phoneRules: [
          (v) => !!v || "phone is required",
          (v) =>
            (v && v.length <= 10) || "number must be less than 11 characters",
          (v) =>
            (v && v.length >= 10) ||
            "number must be greater than 10 characters",
          (v) => /^\d{10}$/.test(v) || "phone number must be a number",
        ],
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    keyword() {
      this.getData();
    },
    page() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/supplier/getalllist/",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
          keyword: this.keyword,
          page: this.page,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.student = response.data.data;
           this.Pagelength = Math.ceil(response.data.totalLength / 20);
         } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    //delete button
    deleteCategory() {
      axios({
        url: "/supplier/remove",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    //add button
    addCategory() {
      if (this.$refs.form.validate()) {
        axios({
          url: "/supplier/details/add",
          method: "POST",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            shopName: this.shopName,
            location: this.location,
            phoneNumber: this.phoneNumber,
          },
        })
          .then((response) => {
            this.adddialogue = false;
            this.appLoading = false;
            if (response.data.status) {
              this.msg = response.data.msg;
              this.showsnackbar = true;

              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
            this.shopName = null;
            this.location = null;
            this.phoneNumber = null;
            this.$refs.form.resetValidation();
            this.name = null;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    //edit button
    editCategory() {
      axios({
        url: "/supplier/details/edit",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          shopName: this.curItem.shopName,
          location: this.curItem.location,
          phoneNumber: this.curItem.phoneNumber,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Updated Sucessfully";
            // this.$router.go(-1)
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>