<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 sm8 md8 lg12 pa-6>
          <v-tabs color="#766BC0" grow class="nsbold" style="font-size: 16px">
             <v-tab>Food Report</v-tab>
            <v-tab>Bill History</v-tab>
            <v-tab>Supplier List</v-tab>
            <v-tab-item>
              <adminFoodReport/>
            </v-tab-item>
            <v-tab-item>
              <adminBillHistory/>
            </v-tab-item>
            <v-tab-item>
              <adminSupplier/>
            </v-tab-item>
          </v-tabs>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
import adminFoodReport from "./adminFoodReport"
import adminBillHistory from "./adminBillHistory"
import adminSupplier from "./adminSupplier"

export default {
  components : {
    adminFoodReport,
    adminBillHistory,
    adminSupplier,
  },
  data() {
    return {
      page: 1,
      Pagelength: 0,
      keyword: null,
      ServerError: false,
      showsnackbar: false,
      msg: null,
      appLoading: false,
    };
  },
  mounted() {
    this.gethostel();
    this.getData();
  },
  watch: {
    // page() {
    //   // this.getData();
    //   this.getData();
    // },
    // keyword() {
    //   if (this.page > 1) this.page = 1;
    //   // this.getData();
    //   this.getData();
    // },
   
  },
  methods: {

    // getData() {
    //   this.appLoading = true;
    //   axios({
    //     method: "get",
    //     url: "/roommap/hostelwise/getlist",

    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     params: {
    //     hostelId: this.hostel,
    //       page: this.page,
    //       limit: 20,
    //       keyword: this.keyword,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.StData = response.data.data;
    //         this.Pagelength = Math.ceil(response.data.totalLength / 20);
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //       this.ServerError = true;
    //     });
    // },
  
  },
};
</script>